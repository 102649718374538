<template>
  <div class="surveys">
    <div class="survey-item">
      <span class="survey-name">
        {{ name }}
      </span>

      <div class="pull-right">
        <span class="survey-progress">
          <span class="survey-progress-bg">
            <span
              class="survey-progress-fg"
              style="width: 30%"
            />
          </span>

          <span class="survey-progress-labels">
            <span
              class="survey-progress-label"
              style="margin: 0;font-size: 11px;"
            > Delivery Status </span>
          </span>
        </span>

        <span class="survey-end-date ended">
          Expected delivery: 10 December 2022
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../config";

export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    price: {
      // type: Number,
      // default: 0
    },
    dueIn: {
      type: String,
      default: "",
    },
    addressName: {
      type: String,
      default: "",
    },
    address: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    orderStatus: {
      type: String,
      default: "",
    },
    orderType: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    resolveImage(src) {
      return config.HOST + src;
    },
  },
};
</script>

<style lang="scss" scoped>
.surveys {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.survey-item {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 20px;
  border-radius: 2px;
  background: white;
  // box-shadow: 0 2px 1px rgba(170, 170, 170, 0.25);
  // box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(18, 205, 254, 0.05) 0px 0px 0px 1px;
}

.survey-name {
  // font-weight: 400;
  -webkit-line-clamp: 2;
  max-height: 40px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 12px;
}

.list .survey-item {
  position: relative;
  padding: 0;
  font-size: 16px;
  line-height: 40px;
}

.list .survey-item .pull-right {
  position: absolute;
  right: 0;
  top: 0;
}
@media screen and (max-width: 800px) {
  .list .survey-item .stage:not(.active) {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .list .survey-item .survey-progress-bg {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .list .survey-item .pull-right {
    position: static;
    line-height: 20px;
    padding-bottom: 10px;
  }
}
.list .survey-country,
.list .survey-progress,
.list .survey-completes,
.list .survey-end-date {
  color: #a1a1a4;
}
.list .survey-country,
.list .survey-completes,
.list .survey-end-date,
.list .survey-name,
.list .survey-stage {
  margin: 0 10px;
}
.list .survey-country {
  margin-right: 0;
}
.list .survey-end-date,
.list .survey-completes,
.list .survey-country,
.list .survey-name {
  vertical-align: middle;
}
.list .survey-end-date {
  display: inline-block;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
}

.survey-stage .stage {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  overflow: hidden;
  border-radius: 50%;
  padding: 0;
  margin: 0 2px;
  background: #f2f2f2;
  text-indent: -9999px;
  color: transparent;
  line-height: 16px;
}
.survey-stage .stage.active {
  background: #a1a1a4;
}

.list .list-only {
  display: auto;
}
.list .grid-only {
  display: none !important;
}

.grid .grid-only {
  display: auto;
}
.grid .list-only {
  display: none !important;
}

.survey-item {
  position: relative;
  display: flex;
  vertical-align: top;
  height: 300px;
  border-radius: 20px;
  // width: 250px;
  // margin: 10px;
}
@media screen and (max-width: 600px) {
  .survey-item {
    display: block;
    width: auto;
    height: 150px;
    margin: 10px auto;
  }
}

.survey-name {
  display: block;
  // max-width: 80%;
  font-size: 16px;
  line-height: 20px;
  -webkit-line-clamp: 2;
  max-height: 40px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-bottom: 5px;
  color: #7a615a;
}

.survey-country {
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
}

.survey-country,
.survey-end-date {
  color: #a1a1a4;
}

.survey-progress {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 20px;
  border-top: 1px solid #eee;
  font-size: 13px;
}

.survey-progress-bg {
  width: 40%;
  display: block;
}
@media screen and (max-width: 200px) {
  .survey-progress-bg {
    display: none;
  }
}

.survey-progress-labels {
  position: absolute;
  right: 20px;
  top: 1px;
  line-height: 45px;
}
@media screen and (max-width: 200px) {
  .survey-progress-labels {
    right: auto;
    left: 10px;
  }
}

.survey-progress-label {
  line-height: 21px;
  font-size: 13px;
  font-weight: 400;
}

.survey-completes {
  line-height: 21px;
  font-size: 13px;
  vertical-align: middle;
}

.survey-stage {
  position: absolute;
  top: 20px;
  right: 20px;
}
.survey-stage .stage {
  display: none;
}
.survey-stage .active {
  display: block;
}

.survey-end-date {
  font-size: 12px;
  line-height: 20px;
}

.survey-progress-label {
  vertical-align: middle;
  margin: 0 10px;
  color: #8dc63f;
}

.survey-progress-bg {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 100px;
  height: 10px;
  border-radius: 100px;
  overflow: hidden;
  background: #eee;
}

.survey-progress-fg {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  background: #8dc63f;
  background: linear-gradient(
    90deg,
    rgb(252, 74, 26) 0%,
    rgb(247, 183, 51) 100%
  );
}
</style>
