<template>
  <div>
    <div class="card-container">
      <!-- <img
        class="mx-auto"
        :src="resolveImage(this.user.avatarLink)"
        style="
          width: 80px;
          height: 80px;
          box-shadow: rgb(0 0 0 / 45%) 0px 0px 5px 0px !important;
          border: 2px solid white;
          border-radius: 50%;
        "
        alt="User Avatar"
      />
      <h3 class="font-bold mt-2">
        {{ user.first_name + " " + user.last_name }}
      </h3>
      <h6 class="lowercase -mt-1 text-xs font-bold">
        {{ user.username }}
      </h6>
      <div class="mt-1 font-light mb-8 text-sm">
        {{ Number(user.CurrentBalancePoints) }} Sonik Points
      </div> -->
      <div class="card-header">
        <div>
          <!-- <avatar
            :fullname="user.first_name + ' ' + user.last_name"
            :size="68"
            style="font-size: 24px; font-weight: 700"
            class="card-avatar-new"
            color="#01A4C0"
          ></avatar> -->
          <img
            class="mx-auto"
            :src="resolveImage(this.user.avatarLink)"
            style="width: 80px; height: 80px; border-radius: 50%"
            alt="User Avatar"
          />
        </div>
        <div style="margin-top: 5px; margin-left: 20px">
          <h1 class="fullname">{{ user.first_name + " " + user.last_name }}</h1>
          <h2 class="email">{{ user.username }}</h2>
          <h2 class="email" style="color: #01a4c0">
            {{ Number(user.CurrentBalancePoints) }} Sonik Points
          </h2>
        </div>
      </div>

      <!-------------------- PROFILE BUTTONS --------------------->
      <div class="card-buttons">
        <button
          :class="{ isActive: active_el == 1 }"
          data-section="#deliveries"
          @click="activate(1, '#deliveries')"
        >
          Deliveries
        </button>
        <button
          :class="{ isActive: active_el == 2 }"
          data-section="#stats"
          @click="activate(2, '#stats')"
        >
          Stats
        </button>
        <button
          :class="{ isActive: active_el == 3 }"
          data-section="#settings"
          @click="activate(3, '#settings')"
        >
          Settings
        </button>
      </div>

      <!-------------------- PROFILE SECTIONS --------------------->
      <div class="card-main">
        <div
          id="deliveries"
          class="card-section"
          :class="{ isActive: active_el == 1 }"
        >
          <!-- <div
            class="card-subtitle text-left pb-2 text-[#806157] mx-[20px] mt-6"
            style="margin-bottom: 0"
          >
            Stats
          </div>
          <div class="card-footer" style="margin-top: 0">
            <div class="stats">
              <p class="count">{{ missionsCompletedCountGetter }}</p>
              <div class="type">Tasks Completed</div>
            </div>
            <div class="stats">
              <p class="count">{{ missionsFailedCountGetter }}</p>
              <div class="type">Tasks Failed</div>
            </div>
            <div class="stats">
              <p class="count">{{ getProfile.toysBought }}</p>
              <div class="type">Toys Bought</div>
            </div>
            <div class="stats">
              <p class="count">£{{ getSpendWalletBalance }}</p>
              <div class="type">Spent</div>
            </div>
          </div> -->
          <div class="card-content" style="padding: 0">
            <div
              class="card-subtitle text-left pb-2 text-[#806157] mx-[20px] mt-6"
              style="margin-bottom: 0"
            >
              Delivery Updates
            </div>

            <div
              v-if="itemsOnTheirWay.length > 0"
              class="walletScroll"
              style="
                padding: 25px 20px;
                scroll-padding: 1.5rem;
                margin-top: -10px;
              "
            >
              <div
                v-for="(item, index) in itemsOnTheirWay"
                :key="index"
                class="walletScroll__item"
                style="max-width: 250px"
              >
                <AppItemsOnTheirWay
                  v-if="item.address != null"
                  :name="item.shopping_cart[0].name"
                  :price="item.shopping_cart[0].price"
                  :due-in="'Status: ' + item.status"
                  :address-name="item.address.type"
                  :address="item.address.address"
                  :image="item.shopping_cart[0].default_picture_link"
                  :order-status="item.status"
                  :order-type="item.shopping_cart[0].categories_id == 5 ? 1 : 0"
                />
              </div>
            </div>

            <div v-else class="recent_container">
              <div
                class="activity_item shadow"
                style="justify-content: center; align-items: center"
              >
                <div
                  style="
                    text-align: center;
                    color: var(--secondary);
                    font-size: 12px;
                    font-weight: 500;
                  "
                >
                  No items found
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-------------------- ADDRESS SECTION --------------------->
        <div
          id="stats"
          class="card-section"
          :class="{ isActive: active_el == 2 }"
        >
          <div
            class="card-subtitle text-left pb-2 text-[#806157] mx-[20px] mt-6"
            style="margin-bottom: 0"
          >
            Stats
          </div>
          <div class="card-footer" style="margin-top: 0">
            <div class="stats">
              <p class="count">{{ missionsCompletedCountGetter }}</p>
              <div class="type">Tasks Completed</div>
            </div>
            <div class="stats">
              <p class="count">{{ missionsFailedCountGetter }}</p>
              <div class="type">Tasks Failed</div>
            </div>
            <div class="stats">
              <p class="count">{{ getProfile.toysBought }}</p>
              <div class="type">Toys Bought</div>
            </div>
            <div class="stats">
              <p class="count">£{{ getSpendWalletBalance }}</p>
              <div class="type">Spent</div>
            </div>
          </div>
        </div>
        <!-------------------- SETTINGS SECTION --------------------->
        <div
          id="settings"
          class="card-section"
          :class="{ isActive: active_el == 3 }"
        >
          <div class="card-content">
            <div class="card-subtitle text-left pb-[20px] text-[#806157]">
              My Information
            </div>

            <!--------------- First Name ---------------->
            <div class="input-label ml-1 text-left">First Name</div>
            <div
              class="rounded-lg p-3 mb-2 flex items-center justify-between border border-[#806157]"
            >
              <div>
                <!-- <div class="text-xs text-left">Name</div> -->
                <div class="text-left text-sm">
                  {{ user.first_name }}
                </div>
              </div>
              <button
                @click="openSheetEditFirstName"
                class="bg-[#49AACB] rounded-full p-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg>
              </button>
            </div>
            <!--------------- End First Name ---------------->
            <!--------------- Last Name ---------------->
            <div class="input-label ml-1 text-left">Last Name</div>
            <div
              class="rounded-lg p-3 mb-2 flex items-center justify-between border border-[#806157]"
            >
              <div>
                <!-- <div class="text-xs text-left">Name</div> -->
                <div class="text-left text-sm">
                  {{ user.last_name }}
                </div>
              </div>
              <button
                @click="openSheetEditLastName"
                class="bg-[#49AACB] rounded-full p-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg>
              </button>
            </div>
            <!--------------- End Last Name ---------------->
            <!--------------- Username ---------------->
            <div class="input-label ml-1 text-left">Username</div>
            <div
              class="rounded-lg p-3 mb-2 flex items-center justify-between border border-[#806157]"
            >
              <div>
                <!-- <div class="text-xs text-left">Name</div> -->
                <div class="text-left text-sm">
                  {{ user.username }}
                </div>
              </div>
              <button
                @click="openSheetEditUserName"
                class="bg-[#49AACB] rounded-full p-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg>
              </button>
            </div>
            <!--------------- End Username ---------------->

            <button
              class="bg-[#ff4f79] border-none text-white font-bold fs-[20px] py-5 px-14 rounded-full mx-auto cursor-pointer mt-6 text-sm block mb-5"
              @click="logOutMobile()"
            >
              Logout
            </button>
          </div>
        </div>
      </div>

      <!-- <div class="card-footer overflow-x-auto">
        <div
          class="
            card-footer__pens
            bg-white
            text-[#303D67]
            p-2
            rounded
            min-w-[120px]
            mr-3
          "
        >
          <span>{{ missionsCompletedCountGetter }}</span>
          <div class="label" style="color: #303d67">Tasks Completed</div>
        </div>
        <div
          class="
            card-footer__followers
            bg-white
            text-[#303D67]
            p-2
            rounded
            min-w-[120px]
            mr-3
          "
        >
          <span>{{ missionsFailedCountGetter }}</span>
          <div class="label" style="color: #303d67">Tasks Failed</div>
        </div>
        <div
          class="
            card-footer__following
            bg-white
            text-[#303D67]
            p-2
            rounded
            min-w-[120px]
            mr-2
          "
        >
          <span>{{ getProfile.toysBought }}</span>
          <div class="label" style="color: #303d67">Toys Bought</div>
        </div>
      </div> -->
    </div>

    <!-- <div style="padding: 0 20px; font-weight: 600">Items on their way</div>
    <div
      v-if="itemsOnTheirWay.length > 0"
      class="walletScroll"
      style="padding: 25px 20px; scroll-padding: 1.5rem; margin-top: -10px"
    >
      <div
        v-for="(item, index) in itemsOnTheirWay"
        :key="index"
        class="walletScroll__item"
        style="max-width: 250px"
      >
        <AppItemsOnTheirWay
          v-if="item.address != null"
          :name="item.shopping_cart[0].name"
          :price="item.shopping_cart[0].price"
          :dueIn="'Status: ' + item.status"
          :addressName="item.address.type"
          :address="item.address.address"
          :image="item.shopping_cart[0].default_picture_link"
          :orderStatus="item.status"
          :orderType="item.shopping_cart[0].categories_id == 5 ? 1 : 0"
        />
      </div>
    </div>

    <div v-else class="recent_container">
      <div
        class="activity_item shadow"
        style="justify-content: center; align-items: center"
      >
        <div
          style="
            text-align: center;
            color: var(--secondary);
            font-size: 12px;
            font-weight: 500;
          "
        >
          No items found
        </div>
      </div>
    </div> -->

    <!-- <div
      class="donateSection"
      style="
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
      "
    >
      <img
        src="http://vietnamvoice.org/wp-content/uploads/2014/03/8446299_orig-1.png"
        width="250"
      /> -->
    <!-- <AppButton text="Donate to charity" color="blue" :mini="true" /> -->
    <!-- </div> -->

    <!------------------------ PROFILE EDIT MODAL ------------------------>

    <Sheet :show.sync="sheetEditChild" :on-close="closeSheetEditChild">
      <label for="username" style="font-size: 16px; display: block"
        >Username</label
      >
      <AppInput
        id="username"
        v-model="editChild.username"
        required
        style="
          margin-bottom: 20px;
          margin-top: 8px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          width: 100%;
        "
      />
      <label for="first_name" style="font-size: 16px; display: block"
        >First name</label
      >
      <AppInput
        id="first_name"
        v-model="editChild.first_name"
        required
        style="
          margin-bottom: 20px;
          margin-top: 8px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          width: 100%;
        "
      />
      <label for="last_name" style="font-size: 16px; display: block"
        >Last name</label
      >
      <AppInput
        id="last_name"
        v-model="editChild.last_name"
        required
        style="
          margin-bottom: 20px;
          margin-top: 8px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          width: 100%;
        "
      />

      <AppButton
        style="margin: 20px auto 0 auto"
        text="Update"
        :mini="true"
        :color="validateEdit"
        class="appCheckout__game__actions__button"
        @click="editChildMethod"
      />
    </Sheet>

    <Sheet :show.sync="sheetEditFirstName" :on-close="closeSheetEditFirstName">
      <div slot="title">Details</div>
      <div>
        <AppInput
          v-model="editChild.first_name"
          maxlength="20"
          :dice="false"
          :required="true"
          type="text"
          name="First Name"
          label="first_name"
          label-align="left"
        />
      </div>
      <div class="flex justify-center">
        <button
          class="bg-[#58C1E1] rounded-[100px] py-3 px-12 text-white text-sm font-bold"
          @click="editChildMethod('firstName')"
        >
          Update
        </button>
      </div>
    </Sheet>
    <Sheet :show.sync="sheetEditLastName" :on-close="closeSheetEditLastName">
      <div slot="title">Details</div>
      <div>
        <AppInput
          v-model="editChild.last_name"
          maxlength="20"
          :dice="false"
          :required="true"
          type="text"
          name="Last Name"
          label="last_name"
          label-align="left"
        />
      </div>
      <div class="flex justify-center">
        <button
          class="bg-[#58C1E1] rounded-[100px] py-3 px-12 text-white text-sm font-bold"
          @click="editChildMethod('lastName')"
        >
          Update
        </button>
      </div>
    </Sheet>
    <Sheet :show.sync="sheetEditUserName" :on-close="closeSheetEditUserName">
      <div slot="title">Details</div>
      <div>
        <AppInput
          v-model="editChild.username"
          maxlength="20"
          :dice="false"
          :required="true"
          type="text"
          name="Username"
          label="username"
          label-align="left"
        />
      </div>
      <div class="flex justify-center">
        <button
          class="bg-[#58C1E1] rounded-[100px] py-3 px-12 text-white text-sm font-bold"
          @click="editChildMethod('userName')"
        >
          Update
        </button>
      </div>
    </Sheet>
  </div>
</template>

<script>
// import AppFamilyMember from "@/components/AppFamilyMember";
import AppItemsOnTheirWay from "../../components/AppItemsOnTheirWay";
import AppButton from "../../components/AppButton";
import AppInput from "../../components/AppInput";
import Sheet from "../../components/Sheet";
import config from "../../config";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    AppItemsOnTheirWay,
    // AppFamilyMember,
    AppButton,
    AppInput,
    Sheet,
  },
  data() {
    return {
      username: "Username",
      name: "Mark Tibi",
      missionsCompletedNumber: 3,
      missionsFailedNumber: 1,
      boughtToysNumber: 5,
      family_and_friends: [],
      items: [],
      transferModal: false,
      editProfileModal: false,
      sheetEditChild: false,
      editChild: {},
      active_el: 1,
      cardState: "#deliveries",
      sheetEditFirstName: false,
      sheetEditLastName: false,
      sheetEditUserName: false,
    };
  },
  methods: {
    ...mapActions([
      "fetchProfile",
      "fetchFamilyAndFriends",
      "setupUser",
      "fetchMissions",
      "editChildAction",
    ]),
    openSheetEditFirstName() {
      this.editChild.first_name = this.user.first_name;
      this.sheetEditFirstName = true;
    },
    closeSheetEditFirstName() {
      this.sheetEditFirstName = false;
    },
    openSheetEditLastName() {
      this.editChild.last_name = this.user.last_name;
      this.sheetEditLastName = true;
    },
    closeSheetEditLastName() {
      this.sheetEditLastName = false;
    },
    openSheetEditUserName() {
      this.editChild.username = this.user.username;
      this.sheetEditUserName = true;
    },
    closeSheetEditUserName() {
      this.sheetEditUserName = false;
    },
    activate: function (el, state) {
      this.active_el = el;
      this.cardState = state;
    },
    openSheetEditChild() {
      this.editChild.username = this.user.username;
      this.editChild.first_name = this.user.first_name;
      this.editChild.last_name = this.user.last_name;
      this.sheetEditChild = true;
    },
    closeSheetEditChild() {
      this.sheetEditChild = false;
    },
    editChildMethod(type) {
      // this.sheetEditChild = false;
      this.editChildAction(this.editChild);

      if (type === "firstName") {
        this.closeSheetEditFirstName();
      } else if (type === "lastName") {
        this.closeSheetEditLastName();
      } else if (type === "userName") {
        this.closeSheetEditUserName();
      }
    },
    logOutMobile() {
      if (confirm("Are you sure you want to log out?")) {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.clear();
        location.replace("https://" + window.location.host + "/?logout=true");
      }
    },
    openTranferModal() {
      this.transferModal = true;
    },
    closeTranferModal() {
      this.transferModal = false;
    },
    getAddress(address) {
      return (
        address.country +
        ", " +
        address.town +
        ", " +
        address.street +
        ", " +
        address.number +
        ", " +
        address.post_code
      );
    },
    resolveImage(src) {
      return config.HOST + src;
    },
  },
  computed: {
    ...mapGetters([
      "user",
      "getProfile",
      "getSpendWalletBalance",
      "getSaveWalletBalance",
      "getFamilyAndFriends",
      "itemsOnTheirWay",
      "takeMiniMissions",
      "missionsCompletedCountGetter",
      "missionsFailedCountGetter",
    ]),
    validateEdit() {
      if (
        this.editChild.username != "" &&
        this.editChild.first_name != "" &&
        this.editChild.last_name != ""
      ) {
        return "blue";
      } else {
        return "";
      }
    },
  },
  created() {
    const user = {
      userId: localStorage.getItem("children_user_id"),
      token: localStorage.getItem("children_token"),
      family_id_check: localStorage.getItem("children_family_id"),
    };
    // console.log(this.itemsOnTheirWay);
    this.fetchProfile();
    this.setupUser(user);
    this.fetchFamilyAndFriends();
    this.fetchFamilyAndFriends();
    // this.fetchMissions();
    // console.log("takemissions");
    // console.log(this.takeMiniMissions);
  },
  mounted() {
    // this.family_and_friends = this.$store.state.familyAndFriends;
    this.items = this.$store.state.itemsOnTheirWay;
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  // background-color: var(--main);
  // color: #fff;
  padding-top: 30px;
  position: relative;
  width: 100%;
  max-width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.card-container .pro {
  color: #231e39;
  background-color: #febb0b;
  border-radius: 3px;
  font-size: 16px;
  font-weight: bold;
  padding: 3px 7px;
  position: absolute;
  top: 30px;
  left: 30px;
}

.card-container .round {
  // border: 1px solid #03bfcb;
  border-radius: 50%;
  padding: 7px;
}

button.primary {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: var(--main);
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  padding: 10px 25px;
  width: auto;
  margin-right: 2.5px;
  font-size: 16px;
}

button.primary.ghost {
  background-color: transparent;
  color: #fff;
  width: auto;
  margin-left: 2.5px;
}

.skills {
  background-color: var(--ternary);
  text-align: left;
  padding: 15px;
  margin-top: 30px;
}

.skills ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.skills ul li {
  border: 1px solid #2d2747;
  border-radius: 2px;
  display: inline-block;
  font-size: 12px;
  margin: 0 7px 7px 0;
  padding: 7px;
}

.card-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  // background: var(--ternary);
  padding: 15px 15px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 15px;
  padding-top: 5px;
}
.card-footer .label {
  display: block;
  margin: 4px 0 0;
  color: #fff;
  font-size: 10px;
  font-weight: 400;
}

.recent_container {
  margin: 20px 20px 0 20px;
}

.activity_item {
  width: 100%;
  height: 90px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  background: white;
  padding: 15px;
  border-radius: 5px;
  border-bottom-right-radius: 15px;
  position: relative;
  overflow: hidden;
  transition: all 200ms ease-in-out !important;
}

/*************************************************::placeholder */

.card {
  margin: auto;
  // overflow-y: auto;
  // position: relative;
  z-index: 1;
  // overflow-x: hidden;
  // background-color: rgba(255, 255, 255, 1);
  display: flex;
  transition: 0.3s;
  flex-direction: column;
  border-radius: 10px;
  height: 100%;
  // box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2);
}

.card[data-state="#deliveries"] {
  .card-main {
    padding-top: 0;
  }
}

.card.isActive {
  .card-header {
    height: 80px;
  }

  .card-cover {
    height: 100px;
    top: -50px;
  }

  .card-avatar {
    transform: none;
    left: 20px;
    width: 50px;
    height: 50px;
    bottom: 10px;
  }

  .card-fullname {
    bottom: 28px;
    font-size: 19px;
  }
  .card-jobtitle {
    left: 86px;
    transform: none;
  }

  .card-fullname {
    bottom: 28px;
    font-size: 19px;
  }

  .card-jobtitle {
    bottom: 10px;
    letter-spacing: 1px;
    font-size: 10px;
  }
}

.card-header {
  position: relative;
  display: flex;
  height: 200px;
  flex-shrink: 0;
  width: 100%;
  transition: 0.15s;
  margin-bottom: 35px;

  // * {
  //   transition: 0.3s;
  // }
}

.card-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  height: 160px;
  top: -20%;
  left: 0;
  will-change: top;
  background-size: cover;
  background-position: center;
  filter: blur(30px);
  transform: scale(1.2);
  transition: 0.5s;
}

.card-avatar {
  width: 80px;
  height: 80px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  object-position: center;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-64px);
  background: rgba(255, 255, 255, 0.5);
  padding: 5px;
}

.card-fullname {
  // position: absolute;
  // bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  transform: translateY(-10px) translateX(-50%);
  // left: 50%;
  color: var(--secondary);
}

.card-jobtitle {
  // position: absolute;
  // bottom: -2px;
  font-size: 14px;
  white-space: nowrap;
  font-weight: 300;
  margin: 0;
  // left: 50%;
  transform: translateX(-50%) translateY(-7px);
}

.card-main {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.card-subtitle {
  font-weight: 800;
  font-size: 20px;
}
.card-content {
  padding: 20px;
  margin-bottom: 65px;
}

.card-desc {
  line-height: 1.6;
  color: var(--secondary);
  font-size: 14px;
  margin: 0;
  font-weight: 400;
}

.card-social {
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 30px;
  svg {
    fill: rgb(165, 181, 206);
    width: 16px;
    display: block;
    transition: 0.3s;
  }
  a {
    color: #8797a1;
    height: 32px;
    width: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    background-color: rgba(93, 133, 193, 0.05);
    border-radius: 50%;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.card-buttons {
  display: flex;
  margin-top: auto;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;

  button {
    margin: 10px;
    flex: 1 1 auto;
    user-select: none;
    background: 0;
    font-size: 14px;
    font-weight: 800;
    border: 0;
    padding: 8px 2px;
    cursor: pointer;
    color: #01a3c02c;
    font-family: var(--sans) !important;
    transition: 0.3s;
    font-weight: bold;
    outline: 0;
    // border-bottom: 3px solid #c4c4c4;
    border: 1px solid #01a3c02c;
    border-radius: 60px;

    &.isActive,
    &:hover {
      color: #01a4c0;
      border: 1px solid #01a4c0;
    }
  }
}
.card-section {
  display: none;
  &.isActive {
    display: block;
    animation: fadeIn 0.6s both;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translatey(40px);
  }
  100% {
    opacity: 1;
  }
}

.card-timeline {
  margin-top: 30px;
  position: relative;
  &:after {
    background: linear-gradient(
      to top,
      rgba(134, 214, 243, 0) 0%,
      rgba(81, 106, 204, 1) 100%
    );
    content: "";
    left: 42px;
    width: 2px;
    top: 0;
    height: 100%;
    position: absolute;
    content: "";
  }
}

.card-item {
  position: relative;
  padding-left: 60px;
  padding-right: 20px;
  padding-bottom: 30px;
  z-index: 1;
  &:last-child {
    padding-bottom: 5px;
  }

  &:after {
    content: attr(data-year);
    width: 10px;
    position: absolute;
    top: 0;
    left: 37px;
    width: 8px;
    height: 8px;
    line-height: 0.6;
    border: 2px solid #fff;
    font-size: 11px;
    text-indent: -35px;
    border-radius: 50%;
    color: rgba(#868686, 0.7);
  }
}

.card-item-title {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}

.card-item-desc {
  font-size: 13px;
  color: #6f6f7b;
  line-height: 1.5;
  font-family: "DM Sans", sans-serif;
}

.card-settings-wrapper {
  margin-top: 20px;
}

.card-settings {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #6f6f7b;
  font-family: "DM Sans", sans-serif;
  line-height: 1.6;
  cursor: pointer;

  & + & {
    margin-top: 16px;
  }

  svg {
    flex-shrink: 0;
    width: 30px;
    min-height: 34px;
    margin-right: 12px;
    transition: 0.3s;
    padding-right: 12px;
    border-right: 1px solid #dfe2ec;
  }
}
.card-header {
  // position: relative;
  display: flex;
  padding-left: 20px;
  flex-direction: row;
  height: 72px;
  flex-shrink: 0;
  width: 100%;
  transition: 0.15s;
  margin-bottom: 35px;
  margin-top: 55px !important;
}
.card-avatar-new {
}
.fullname {
  font-size: 20px;
  font-weight: 800;
}
.email {
  font-size: 14px;
  font-weight: 300;
  text-align: start;
}
.input-label {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
}

.stats {
  background-color: #303d67;
  width: 45%;
  height: auto;
  border-radius: 30px;
  padding: 5%;
  margin: 5px;
}

.count {
  color: #58c1e1;
  text-align: left;
  font-size: 20px;
  font-weight: 700;
}
.type {
  font-size: 12px;
  text-align: left;
  font-weight: 500;
}
</style>
